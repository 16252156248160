.search {
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.search::-webkit-scrollbar {
    display: none;
}

.ais-Hits {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px
}

.ais-Hits > ol {
    padding: 0
}

.ais-Pagination-list {
    display: flex;
    flex-direction: row
}

.ais-Pagination-list li {
    flex: 1;
    display: flex;
    margin: 4px;
}
.ais-Hits-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
}
.ais-SearchBox-input {
    background-color: transparent;
    border: none;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
}
.ais-SearchBox-form {
    display: flex;
    width: 100%;
    max-height: 50px;
}
.ais-SearchBox-input:focus {
    outline: none;
    border: 1px solid var(--text-Xmuted)
}
.ais-SearchBox-form > button {
    fill: var(--text-main) !important;
    background-color: var(--background-active);
    border: none;
}

.ais-SearchBox {
    display: flex;
    width: 100%;
    max-width: 500px;
    margin: 8px;
}

.ais-Hits-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    background-color: var(--surface);
    margin: 4px;
    padding: 8px;
    max-Width: 800px;
}

.ais-Hits-item > .post__body > p {
    width: 100%;
    max-width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ais-Hits-item > .post_-body img {
    width: 100%;
    max-width: 800%;
    display: flex;
    flex: 1;
}

@media (max-width: 600px) {
    
}

@media (min-width: 601px) {
    .search {
        margin-top: 60px
    }
}