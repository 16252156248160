.note {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: var(--surface);
    margin: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);   

}

.note.read {
    background-color: var(--brand-background-active);
    box-shadow: none;   
}

.note__body .subtitle2 {
    text-decoration: none;
}

.note p, h6 {
    margin: 0;
    padding: 16px;
}

.note__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48px;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.note__body {
    flex: 0.8;
    width: 100%;
}

.note__tools {
    flex: 0.2;
    display: flex;
    flex-direction: row;
    align-items: center
}

.note__tools button {
    font-weight: var(--light);
    width: 100%;
}

#notification-popup-menu .MuiList-root {

    display: flex !important;
    padding: 0;
    flex-direction: column !important;
}

#notification-popup-menu .MuiList-root > * {
    font-size: 14px !important;
    padding: 4px;
    margin: 0;
}
