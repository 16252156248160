.sidebarOption {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.sidebarOption:hover {
    background-color: var(--brand-background-active);
    color: var(--brand-color);
    transition: color 100ms ease-out;
}

.sidebarOption>.MuiSvgIcon-root {
    padding: 8px;
}

.sidebarOption>h6 {
    margin-top: 0;
    margin-bottom: 0;
}

.sidebarOption--active {
    color: var(--brand-color);
}