body {
    --brand-color: #5e0deb;
    --brand-background: #f6f9f9;
    --text-main: rgba(0, 0, 0, 0.8);
    --text-muted: rgba(82, 82, 82, 0.8);
    --text-Xmuted: rgba(176, 176, 176, 0.8);

    --text-inverse: rgba(255, 255, 255, 0.8);
    --brand-background-active: #e8e6f6;
    --surface: #fff; 
    --thin: 100;
    --xlight: 200;
    --light: 300;
    --normal: 400;
    --medium: 500;
    --semibold: 600;
    --bold: 700;
    --xbold: 800;
    color: var(--text-main);
    background-color: var(--brand-background);
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
}



body::-webkit-scrollbar {
    display: none;
  }

body.dorkMode {
    --brand-color: #08f7f7;
    --brand-background: #2c2b2d;
    --text-main: rgba(255, 255, 255, 0.8);
    --text-muted: rgba(218, 217, 217, 0.8);
    --text-Xmuted: rgba(125, 125, 125, 0.8);
    --text-inverse: rgba(0, 0, 0, 0.8);
    --brand-background-active: #38373a;
    --surface: #3b3b3b; 
}

body>* {
    margin: 0;
}
input, textarea {
    color: var(--text-main) !important;
    border-color: var(--text-main) !important;
}
h1, h2, h3, h4, h5, h6, p {
    color: var(--text-main);
}
.MuiButtonBase-root {
    color: var(--text-main) !important;
}

.MuiInputBase-colorPrimary * {
    border-color: var(--text-Xmuted) !important;
}

.MuiPaper-root {
    background-color: var(--brand-background)!important;
    padding: 16px;
}

.MuiFormHelperText-root {
    color: var(--text-muted) !important
}

h1 {
    font-size: 6rem;
    font-weight: var(--light);
}
h2 {
    font-size: 3.75rem;
    font-weight: var(--light);
}
h3 {
    font-size: 3rem;
    font-weight: var(--normal);
}
h4 {
    font-size: 2.125rem;
    font-weight: var(--normal);
}
h5 {
    font-size: 1.5rem;
    font-weight: var(--normal);
}
h6 {
    font-size: 1.25rem;
    font-weight: var(--medium);

}
.subtitle1 {
    font-size: 1rem;
    font-weight: var(--regular);
}
.subtitle2 {
    font-size: .875rem;
    font-weight: var(--medium);
}
.body1 {
    font-size: 1rem;
    font-weight: var(--regular);
}
.body2 {
    font-size: .875rem;
    font-weight: var(--regular);
}
.buttonText {
    font-size: .875rem;
    font-weight: var(--medium);
}
.captionText {
    font-size: .75rem;
    font-weight: var(--regular);
}

.overlineText {
    font-size: .625rem;
    font-weight: var(--regular);
}

.app {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    min-height: 100vh;
}
.app::-webkit-scrollbar {
    display: none;
  }
  .loggedIn::-webkit-scrollbar {
    display: none;
  }

.jointhaus {
    font-family: 'Finger Paint', cursive;
}
.brandText {
    font-size: 4rem;
    font-weight: 800;
    color: var(--brand-color)
}  

blockquote {
    border-left: 2px solid var(--brand-background-active);
    padding-left: 16px;
    margin: 0;
    font-size: .8rem;    
}
label {
    color: var(--text-main) !important;
}

/* Whole app styles */

.brand__button {
    background-color: var(--brand-color) !important;
    border: none !important;
    color: var(--text-inverse) !important;
    border-radius: 30px !important;
}

.brand__label {
    display: flex;
    align-items: center;
    color: var(--brand-color)
}

html {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */ 
}

html::-webkit-scrollbar {
    display: none;
}

@media (max-width: 600px) {
    .loggedIn {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        max-width: 100vw;
        flex-grow: 1;
        padding-bottom: 100px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

@media (min-width: 601px) {

    .loggedIn {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
        flex-grow: 1;
        padding-bottom: 100px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}