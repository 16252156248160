.MuiList-root {
    columns: 8 auto;
}

.emojiPicker__picked > p {
    margin: 0;
}

.emojiPicker__picking > .MuiOutlinedInput-root > * {
    margin: 0;
    padding: 0px;
    border: 0px;
}

.emojiPicker__picking {
    position: relative;
    top: 1px;
}