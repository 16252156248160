.post {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--brand-background);
    padding-top:8px;
    padding-bottom: 8px;
    padding-left:8px;
    padding-right:8px;
    background-color: var(--surface);
}

.post > * {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.post__textSection2 img {
    width: 100%;
}
.post .MuiSvgIcon-root {
    color: var(--brand-color)
}

.post__replies button {
    font-size: 0.825rem;
    font-variant: small-caps;
}

.post__body {
    flex: 1;
}
.isReply {
    flex: 1;
    border-left: 3px solid var(--brand-background-active);
    padding-left: 12px
}

.post__body img {
    border-radius: 12px;
    width: 100%;
}


.post__textContainer {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: column;
}

.post__textSection1 {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    margin-left: 4px;
    margin-bottom: 8px;
}

.post__textCol1 {
    display: flex;
    align-items: center;
}

.post__textCol1>* {
    align-self: center;
    text-decoration: none;
    margin-right: 4px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.post__textCol2 {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.post__textCol2 > * {
    margin-right: 4px;
    color: var(--text-muted);
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    color: var(--text-muted)
}

.post__textCol2 > a {
    display: flex;
    font-style: italic; 
    color: var(--text-Xmuted) !important;
    text-decoration: none !important;
}

.post__textCol2 > a:hover {
    text-decoration: underline !important;
}

.post__textCol2 > a:visited {
    color: var(--text-Xmuted) !important;
}

.post__textCol2 > a:visited > span {
    color: var(--text-muted) !important;
}

.post__textSection2 {
    padding-right: 16px;
    padding-left: 16px;
}

.post__replies {
    background-color: var(--surface)
}

.post__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding-left: 4px;
    padding-right: 4px;
    max-width: 800px;
}

.post__footerBoost {
    display: flex;
    justify-content: center;
    align-items:center;
    align-content: center;
    flex: 0.2
}

.post__footerBoost p {
    margin: 0
}

.post__footerMore {
    display: flex;
    justify-content: center;
    align-items:center;
    align-content: center;
    flex: 0.2
}

.post__footerLikes {
    display:flex;
    justify-content: center;
    align-items:center;
    align-content: center;
    flex: 0.2
}

.post__footerLikes p {
    margin: 0
}

.post__footerReplies {
    display: flex;
    flex-direction: row;
    flex: 0.2
}

.post__rule{
    color: var(--brand-color)
}

.post__replyButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.post__replyButton  p {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 4px;
    
}

.post__textSection2 h2, .post__textSection2 h1, .post__textSection2 h3 {
    margin: 0;
    font-size-adjust: 0.3;
    
    font-weight: 300
}

.post__textSection2 p {
    margin: 0;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}


.post__textSection2themes {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    max-width: 100%;
    flex-wrap:wrap;
    align-content: stretch;

}
.postTheme {
    margin: 2px;
    color: var(--text-muted);
    background-color: var(--brand-background);
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 6px;
    border-radius: 6px;
    text-decoration: none;
    align-items: center;
    max-width: 200px;
    display: flex;
    word-break: break-all;
    display:block;
    height:fit-content;
    max-height: 14px;
    overflow: hidden;
}

.postTheme > * {
}

.postTheme > .MuiSvgIcon-root {
    margin-left: 2px !important
}

.postTheme:hover {
    box-shadow: 1px 1px 1px rgba(0,0,0,0.16), 1px 1px 1px rgba(0,0,0,0.23);
    color: var(--text-main);
    transition: 0.1s
}


.themes__input .MuiOutlinedInput-input {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px !important;

}

.post__clickListener {
    width:100%;
    position: absolute;
    height: 190%;
    z-index: -1;
}

#demo-popup-menu .MuiPaper-root {
    padding: 12px !important;
}
#demo-popup-menu .MuiList-root {

    display: flex !important;
    padding: 0;
    flex-direction: column !important;
}

#demo-popup-menu .MuiList-root > div > * {
    font-size: 14px !important;
    padding: 4px;
    margin: 0;
}


@media (max-width: 600px) {
    .feed {
        flex: 1
    }
    .post__textSection1 {
        flex-direction: column;
        flex: 1;
        max-height: 50px;

    }
    .post__textCol2 {
        flex-direction:row;
        margin-top: 4px;

        justify-content: flex-end;
    }

}
@media (min-width: 601px) {
    .post__textSection1 {
        flex-direction: row;
        align-items: center;
        flex: 1;
        align-content: stretch;
        max-height: 50px;

    }
    .post__textCol1 { 
        flex-direction:row;
        flex: 0.6;
     }
    .post__textCol2 { 
        flex-direction:row;
        flex: 0.4;
        justify-content: flex-end;
     }

}

