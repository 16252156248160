.login {
    height: 100%;
    max-height: 100vh;
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    padding: 32px;
}

.login__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.login__oAuthPane {
    display: flex;
    flex: 0.5;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
}

.login__oAuthPane h1 {
    margin-top: 0;
}

.login__modalTextContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.modalClose {
    align-self: flex-end;
}


