.MuiTextField-root:hover {
    cursor: pointer !important;
}
.MuiFormControl-root:hover {
    cursor: pointer !important;
}

.descriptionButton {
    text-transform: none !important;
    font-weight: var(--regular) !important;
    max-width: 80%;
    margin: 8px !important;
    font-size: 11px !important;
    align-self: center;
}

.descriptionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}
.descriptionForm {
    display: flex;
    flex-direction: column;
}
.descriptionInput {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}
