.userStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.userStatus > * {
    margin-right: 4px;
}

.userStatus > a {
    text-decoration: none !important;
    color: var(--text-main);
    cursor: pointer;
}

.userStatus > a:hover {
    color: var(--brand-color);
    transition: color 0.5s
}