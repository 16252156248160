.notifications {
}

.notifications__tray {
    max-width: 400px;
    width: 100%;
}

@media (max-width: 600px) {
    .notifications {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        width: 100%;
    }
}

@media (min-width: 601px) {
    .notifications {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        width: 100%;
        margin-top: 54px;

    }

}