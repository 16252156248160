.postForm {
    padding-bottom: 16px;
    flex: 1;
    position: relative;
    z-index: 1000;
    background-color: var(--surface);
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    padding-bottom: 48px;
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;  
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.postForm::-webkit-scrollbar {
    display: none;
  }

.postForm::-webkit-scrollbar {
    display: none;
  }

.postForm > form {
    width: 100%;
}

.postForm__input {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    max-width: 600px;
}

.postForm__input>input {
    flex: 1;
    margin-left: 20px;
    font-size: 20px;
    border: none;
}



.postForm__imageInput {
    border: none;
    padding: 10px
}

.postForm__header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.postForm__header>* {
    margin-left: 2px;
    margin-right: 2px;
}

.MuiOutlinedInput-notchedOutline {
    border: none;
}
.postForm__textInput > .MuiOutlinedInput-root {
    font-size: 14px !important;
    margin-top: 8px !important;
    padding: 8px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
}

@media (max-width: 600px) {
    .postForm {
        max-width: 100vw;
        background-color: var(--surface);
    }
}

@media (min-width: 601px) {
    .postForm {
    }
}