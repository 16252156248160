.sidebar {
    display:flex;
    overflow-y: hidden !important;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

.sidebar .postForm {
    background-color: var(--brand-background);
    padding-left: 16px;
    min-width: 300px;
}

.sidebar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}


.sidebar__drawer {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.sidebar__MenuButtonPanel {
}

@keyframes slideaway {
    from { display: flex; }
    to { transform: translateY(40px); opacity: 0;}
  }

  @media (max-width: 600px) {
    .sidebar {
        top: 0px;
        right: 0px;
        position: absolute;
        z-index: 5;
    }
    .sidebar .postForm {
        max-width: 350px;
    }
}

@media (min-width: 601px) {
    .sidebar {
        display: flex;
        flex-direction: column;
    }
}