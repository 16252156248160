.feed::-webkit-scrollbar {
    display: none;
}



#flipmove {
    width: 100% !important;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.feed__header h6 {
    font-size: 1rem;
    font-weight: var(--bold);
    margin: 0.5rem;
}

.feed__tray {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);   
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 8px;
    margin-right: 8px;
    max-width: 800px;
    width:100%;
}

.MuiToggleButton-root {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-radius: 0 !important;
}

.Mui-selected {
    border-bottom: 2px solid var(--brand-color) !important;
}

@media (max-width: 600px) {
    .feed__header {
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        bottom: 0;
        background-color: var(--brand-background);
        z-index: 1;
        border-top: 2px solid var(--brand-color);
        padding-bottom: 24px;
        padding-top: 6px;
        justify-content: center;
        width: 100%;
    }
    
    .feed {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        width: 100%;
        
    }
}

@media (min-width: 601px) {
    .feed__header {
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        top: 0;
        background-color: var(--brand-background);
        z-index: 1;
        border-bottom: 2px solid var(--brand-color);
        padding-top: 24px;
        padding-top: 6px;
        justify-content: center;
        width: 100%;
    }
    .feed {
        margin-top: 58px;
    }
    .feed {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        width: 100%;
    }

}