.profileChip {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    padding: 12px;
    margin: 4px;
    background-color: var(--surface);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);   

}
.profileChip__section {
    flex: 1;
    display: flex;
    padding: 4px;
    flex-direction: column;
}

.profileChip p.captionText {
    font-style: italic;
    cursor: pointer;
    flex: 1;

}


.profileChip__name * {
    margin-right: 4px;
}

.profileChip a {
    color: var(--text-main) !important;
}

.profileChip p.captionText > a:hover {
    text-decoration: underline !important;
}

.profileChip__section a {
    text-decoration: none !important;
}

.profileChip__section > span {
    flex: 1;
    padding: 1px;
}

.profileChip__section.blocked {
    flex: 0.3;
    align-items: flex-start;
    padding: 4px;
}

.profileChip__section.avatar {
    flex: 0.1
}

@media (max-width: 600px) {
    .profileChip {
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .profileChip__section.blocked {
        flex: 1
    }
    .profileChip__section.center {
        min-width: 300px;
    }
}