.profile {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.profile__container {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.profile__headerContainer{
    margin-top: 20vh;
    display: flex;
    flex: 1;
    backdrop-filter: blur(3px);
    justify-content: center; 
    width: 100%;
}
.profile__header {
    flex: 1;
    max-width: 600px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    background-color: var(--brand-background);
    align-items: center;
    justify-content: flex-end;
}



.profile__header > h1.subtitle1 {
    margin: 2px;
}



.feed__headerProfileIcon {
    margin-left: auto;
    align-self: flex-end;
}



.profile__header .MuiAvatar-root {
    margin-bottom: 8px;
}

.profile__header h2.subtitle1 {
    margin-top: 4px;
    margin-bottom: 0px;
}

.profile__header > p.captionText {
    font-style: italic;
    margin-bottom: 8px;
    margin-top: 2px;
}

.profile__header > p.captionText a {
    text-decoration: none !important;
}
.profile__header > p.captionText a:hover {
        text-decoration: underline !important;
}

.profile__headerOptions {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: row !important;
    width: 100%;
    max-width: 300px;
}

.profile__headerOptions-option {
    margin-left: 8px;
    margin-right: 8px
}

.bannerTools {
    position: relative;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    margin-bottom: 16px;
    width: 100%;
}

.bannerTools > * {
    margin-top: 12px;
    background-color: var(--brand-background)
}

.feed__header > .sidebarOption {
    align-self: flex-end;
    margin-left: auto;
}
.profile__body {
    display: flex;
    width: 100%;
}

.profile__settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center
}




.profile__headerDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    word-wrap: anywhere;
}



.profile__headerDescription p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.profile__headerDescription .MuiButtonBase-root {
    line-height: 1.1 !important;
    text-align: left;
}

.profile .feed {
    margin-top: 10px;
}